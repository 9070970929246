import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
} from "../../styledComponents/article"

import LayoutNoStripe from "../../components/layoutNoStripe"
import SEO from "../../components/seo"

const WhatIsPermissioning = ({ data, location }) => {
  const pageTitle = data.allWpInformation.nodes[0].title
  const pageContent = data.allWpInformation.nodes[0].content
  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <SEO title={pageTitle} />
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader>
        <h1 itemProp="headline">{pageTitle}</h1>
        <ArticleDate offScreen dateTime={data.allWpInformation.nodes[0].dateGmt}>{data.allWpInformation.nodes[0].date}</ArticleDate>
        </ArticleHeader>

        {!!pageContent && (
          <ArticleSection itemProp="articleBody">
            {parse(pageContent)}
          </ArticleSection>
        )}
      </Article>
    </LayoutNoStripe>
  )
}

export default WhatIsPermissioning

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpInformation(filter: {slug: {eq: "what-is-permissioning-transformation"}}) {
      nodes {
        title
        excerpt
        content
        uri
        slug
        date
        dateGmt
      }
    }
    
  }
`
